:root {
  // Note: Custom variable values only support SassScript inside `#{}`.

  // Theme colors varibles
  @each $color, $value in $theme-colors {
    --#{$prefix}#{$color}: #{$value};
  }

  // Scroll margin top and Stack sticky top related
  --#{$prefix}scroll-mt: calc(#{$header-height} + #{$global-scroll-margin-top});

  // Set breadcrumb height to 0px if breadcrumb is disabled
  --#{$prefix}breadcrumb-height: 0px;

  // hr style
  --#{$prefix}hr-background-color: #{darken($global-border-color, 5%)};
  --#{$prefix}hr-before-color: #{lighten($single-link-hover-color, 4%)};
}

// Dark theme
[data-theme=dark] {
  @each $color, $value in $theme-colors {
    --#{$prefix}#{$color}: #{darken($value, 5%)};
  }

  --#{$prefix}hr-background-color: #{lighten($global-border-color-dark, 5%)};
  --#{$prefix}hr-before-color: #{darken($global-link-hover-color-dark, 4%)};
}
